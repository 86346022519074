import { RefreshIcon, MinusIcon } from '@heroicons/react/outline';
import TableRow from 'components/molecules/TableRow';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { IVehicleFault } from 'types/VehicleFaults';
import { IVehicleIncident } from 'types/VehicleIncidents';
import { IVehicleSurveySubmissions } from 'types/Surveys';

export interface ITable {
	headings: string[];
	data?: (IVehicleFault | IVehicleIncident | IVehicleSurveySubmissions)[];
	refetchData: () => void;
	splitAssignedToMe?: boolean;
	hideColumns?: boolean;
	removeIdFromList: (id: string | number) => void;
}

const Table: React.FC<ITable> = ({
	headings,
	data,
	refetchData,
	splitAssignedToMe = true,
	hideColumns = false,
	removeIdFromList,
}) => {
	const [rowData, setRowData] = useState(data);
	const [assigneeData, setAssigneeData] = useState(data);
	const user = useAppSelector((state) => state.user);
	const [showAssignedRows, setShowAssignedRows] = useState(true);
	const [showAllRows, setShowAllRows] = useState(true);

	type rowDataType =
		| IVehicleFault
		| IVehicleIncident
		| IVehicleSurveySubmissions;

	useEffect(() => {
		if (data && user) {
			const assigneeRows: (
				| IVehicleFault
				| IVehicleIncident
				| IVehicleSurveySubmissions
			)[] = [];
			const otherRows: (
				| IVehicleFault
				| IVehicleIncident
				| IVehicleSurveySubmissions
			)[] = [];
			data.map((row: rowDataType) => {
				if (
					row?.assignees?.some(
						(assignee) => user?.id === Number(assignee.id)
					) &&
					splitAssignedToMe
				) {
					return assigneeRows.push(row);
				} else {
					return otherRows.push(row);
				}
			});
			setAssigneeData(assigneeRows);
			setRowData(otherRows);
		}
	}, [data, user, splitAssignedToMe]);

	const handleAssigneeRows = () => {
		setShowAssignedRows((prevState) => !prevState);
	};

	const handleAllRows = () => {
		setShowAllRows((prevState) => !prevState);
	};

	return (
		<div className="w-full bg-gray-200 px-4 z-10">
			<table className="w-full border bg-gray-200 border-separate border-spacing-0 z-30">
				<thead className="bg-gray-200 relative">
					<tr className="bg-gray-200 sticky top-[94px] z-30">
						<th
							scope="col"
							className="py-7 w-10 text-left text-sm font-semibold text-gray-700"
						>
							<RefreshIcon
								className="h-6 w-6 cursor-pointer"
								onClick={refetchData}
							/>
						</th>
						{headings.map((heading) => (
							<th
								scope="col"
								className="px-3 py-7 text-left text-sm font-semibold text-gray-700"
								key={heading}
							>
								{heading}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{!assigneeData && !rowData && (
						<tr>
							<td colSpan={headings.length}>Loading...</td>
						</tr>
					)}

					{assigneeData && (
						<>
							{assigneeData.length > 0 && (
								<tr>
									<th
										colSpan={2}
										className="text-left text-gray-700 font-bold text-base py-4 border-t border-gray-300 border-solid"
									>
										Assigned to Me
									</th>
									<th
										colSpan={headings.length - 1}
										className=" text-gray-700 font-bold text-base py-4 border-t border-gray-300 border-solid"
									>
										<div className="flex justify-end">
											<MinusIcon
												width={18}
												onClick={handleAssigneeRows}
												className="cursor-pointer"
											/>
										</div>
									</th>
								</tr>
							)}
							{showAssignedRows &&
								assigneeData.map((row) => (
									<TableRow
										data={row}
										key={row.id}
										refetchData={refetchData}
										removeIdFromList={removeIdFromList}
									/>
								))}
						</>
					)}

					{rowData && (
						<>
							{rowData.length > 0 && splitAssignedToMe && (
								<tr>
									<th
										colSpan={2}
										className="text-left text-gray-700 font-bold text-base py-4"
									>
										All
									</th>
									<th
										colSpan={headings.length - 1}
										className=" text-gray-700 font-bold text-base py-4"
									>
										<div className="flex justify-end">
											<MinusIcon
												width={18}
												onClick={handleAllRows}
												className="cursor-pointer"
											/>
										</div>
									</th>
								</tr>
							)}
							{showAllRows &&
								rowData.map((row) => (
									<TableRow
										data={row}
										key={row.id}
										refetchData={refetchData}
										hideColumns={hideColumns}
										removeIdFromList={removeIdFromList}
									/>
								))}
						</>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default Table;

import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import SlideDown from 'components/atoms/SlideDown';
import Text from 'components/atoms/Text';
import TextareaInput from 'components/atoms/TextareaInput';
import ToggleButtonV2 from 'components/atoms/ToggleButtonV2';
import Button from 'components/atoms/Button';
import React, { useEffect, useState } from 'react';
import {
	useGetIncidentSection4Mutation,
	useUpdateIncidentSection4Mutation,
} from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import {
	IOption,
	ISection4ErrorStates,
	IVehicleIncidentSection4,
	IVehicleIncidentSection4Payload,
	section4RadioButtons,
	IQueryReportSection,
} from 'types/VehicleIncidents';
import { disableWriteAccess } from 'utils/disableWriteAccess';
import { isCheckBoxChecked } from 'utils/isCheckBoxChecked';
import FormCheckBoxGroupV2 from '../../molecules/FormCheckboxGroupV2';
import RadioV2 from '../../atoms/RadioV2';
import Divider from '../../atoms/Divider';
import LoaderSpinner from '../../atoms/LoaderSpinner';

export interface IIncidentSection4 {
	sectionId: number | null;
	incidentSection4: IVehicleIncidentSection4;
	setIncidentSection4: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection4>
	>;
	setSection4IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection4ErrorStates>
	>;
	incidentSection3Injuries: boolean;
	setSection: (section: IQueryReportSection) => void;
	handleValidate: () => void;
	savingIncident: boolean;
	setSavingIncident: (input: boolean) => void;
	readTabs: { name: string; isRead: boolean }[];
	setReadTabs: (input: { name: string; isRead: boolean }[]) => void;
}

const IncidentSection4: React.FC<IIncidentSection4> = ({
	sectionId,
	incidentSection4,
	setIncidentSection4,
	setSection4IncidentErrorStates,
	incidentSection3Injuries,
	setSection,
	handleValidate,
	savingIncident,
	setSavingIncident,
	readTabs,
	setReadTabs,
}) => {
	const [changedItem, setChangedItem] = useState<string>('');
	const [section4, setSection4] =
		useState<IVehicleIncidentSection4>(incidentSection4);
	const [initialSetSection, setInitialSetSection] = useState<boolean>(false);
	const [isNotifiableIncident, setIsNotifiableIncident] = useState(
		section4.isNotifiableIncident
	);
	const [isLifeSavingBreach, setIsLifeSavingBreach] = useState(
		section4.isLifeSavingBreach
	);
	const [isCodeOfConductBreach, setIsCodeOfConductBreach] = useState(
		section4.isCodeOfConductBreach
	);

	const [getIncidentSection4, { data: section4Data, isSuccess, isLoading }] =
		useGetIncidentSection4Mutation();
	const [updateIncidentSection4, { isLoading: saving }] =
		useUpdateIncidentSection4Mutation();
	const userPermission = useAppSelector((state) => state.user.permissions);
	const incidentBranchId = useAppSelector(
		(state) => state.vehicleIncident.incidentBranchId
	);
	const disableIncidentWriteAccess = disableWriteAccess(
		'incident_level',
		userPermission,
		incidentBranchId
	);

	useEffect(() => {
		if (saving) {
			setSavingIncident(true);
		} else {
			setSavingIncident(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saving]);

	useEffect(() => {
		setIncidentSection4(() => {
			return {
				...section4,
				isNotifiableIncident,
				isCodeOfConductBreach,
				isLifeSavingBreach,
			};
		});
	}, [
		section4,
		setIncidentSection4,
		isCodeOfConductBreach,
		isLifeSavingBreach,
		isNotifiableIncident,
	]);

	useEffect(() => {
		if (section4Data) handleOnBlur();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNotifiableIncident, isCodeOfConductBreach, isLifeSavingBreach]);

	useEffect(() => {
		setIsNotifiableIncident(section4.isNotifiableIncident);
	}, [section4.isNotifiableIncident]);

	useEffect(() => {
		setIsLifeSavingBreach(section4.isLifeSavingBreach);
	}, [section4.isLifeSavingBreach]);

	useEffect(() => {
		setIsCodeOfConductBreach(section4.isCodeOfConductBreach);
	}, [section4.isCodeOfConductBreach]);

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection4(sectionId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isSuccess && section4Data) {
			const {
				severity_rating,
				reported_to,
				reference_number,
				notes,
				notifiable_incident,
				life_saving_rule_breach,
				code_of_conduct_breach,
				incident_category,
				life_saving_rules,
				code_of_conduct,
				incident_mechanism,
				incident_agency,
				incident_mechanism_other_description,
				incident_agency_other_description,
				mvi_causation,
			} = section4Data.data;

			let selectedRadioButton = section4.severityRating;
			section4RadioButtons.forEach((item) => {
				if (item.title === severity_rating) {
					selectedRadioButton = item.id;
				}
			});

			const incidentMechanismArray: IOption[] = [...incident_mechanism].sort(
				(a, b) =>
					a.title === 'Other'
						? 1
						: b.title === 'Other'
						? -1
						: a.title.localeCompare(b.title)
			);
			const incidentAgencyArray: IOption[] = [...incident_agency].sort((a, b) =>
				a.title === 'Other'
					? 1
					: b.title === 'Other'
					? -1
					: a.title.localeCompare(b.title)
			);
			const mviCausationArray: IOption[] = [...mvi_causation];

			setSection4({
				...section4,
				severityRating: selectedRadioButton,
				incidentCategory: incident_category,
				lifeSavingRules: life_saving_rules,
				codeOfConduct: code_of_conduct,
				referenceNumber: reference_number || '',
				notes: notes || '',
				reportedTo: reported_to || '',
				incidentAgencyOtherInput: incident_agency_other_description,
				incidentAgency: incidentAgencyArray,
				incidentMechanismOtherInput: incident_mechanism_other_description,
				incidentMechanism: incidentMechanismArray,
				mviCausation: mviCausationArray,
			});
			setIsNotifiableIncident(notifiable_incident);
			setIsLifeSavingBreach(life_saving_rule_breach);
			setIsCodeOfConductBreach(code_of_conduct_breach);
		}
		//removes dependency for "section 4"
		// eslint-disable-next-line
	}, [isSuccess, section4Data]);

	const handleSection4Update = (
		property: string,
		value: IOption[] | string,
		disableUpdateRequest?: boolean
	) => {
		setChangedItem(property);
		setSection4({
			...section4,
			[property]: value,
		});
		// if (!disableUpdateRequest) {
		// 	handleOnBlur();
		// }
	};

	useEffect(() => {
		if (
			section4Data &&
			!(changedItem === 'reportedTo') &&
			!(changedItem === 'referenceNumber') &&
			!(changedItem === 'notes') &&
			initialSetSection
		) {
			handleOnBlur();
		} else if (
			section4Data &&
			!(changedItem === 'reportedTo') &&
			!(changedItem === 'referenceNumber') &&
			!(changedItem === 'notes')
		) {
			setInitialSetSection(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [section4]);

	const handleOnBlur = async () => {
		if (!sectionId || disableIncidentWriteAccess) {
			return;
		}

		let convertRadioLabel = section4.severityRating;
		section4RadioButtons.forEach((item) => {
			if (item.id === section4.severityRating) {
				convertRadioLabel = item.title;
			}
		});

		const incidentMechanismCheckBoxGroup =
			section4.incidentMechanism as IOption[];
		const incidentAgencyCheckBoxGroup = section4.incidentAgency as IOption[];
		const mviCausationCheckboxGroup = section4.mviCausation as IOption[];

		const payload: IVehicleIncidentSection4Payload = {
			id: sectionId,
			severity_rating: convertRadioLabel,
			incident_category: section4.incidentCategory,
			notifiable_incident: isNotifiableIncident,
			reported_to: section4.reportedTo,
			reference_number: section4.referenceNumber,
			notes: section4.notes,
			life_saving_rule_breach: isLifeSavingBreach,
			life_saving_rules: section4.lifeSavingRules,
			code_of_conduct_breach: isCodeOfConductBreach,
			code_of_conduct: section4.codeOfConduct,
			incident_mechanism: incidentMechanismCheckBoxGroup,
			incident_agency: incidentAgencyCheckBoxGroup,
			incident_mechanism_other_description:
				section4.incidentMechanismOtherInput,
			incident_agency_other_description: section4.incidentAgencyOtherInput,
			mvi_causation: mviCausationCheckboxGroup,
		};

		await updateIncidentSection4(payload);

		const errorStates = {
			section_4_incident_category: isCheckBoxChecked(section4.incidentCategory),
			section_4_reported_to: section4.reportedTo !== '',
			section_4_reference_number: section4.referenceNumber !== '',
			section_4_life_saving_rules: isCheckBoxChecked(section4.lifeSavingRules),
			section_4_code_of_conduct_checkbox: isCheckBoxChecked(
				section4.codeOfConduct
			),
			section_4_incident_mechanism:
				isCheckBoxChecked(section4.incidentMechanism) ||
				incidentSection3Injuries,
			section_4_incident_agency:
				isCheckBoxChecked(section4.incidentAgency) || incidentSection3Injuries,
		};

		setSection4IncidentErrorStates(errorStates);
	};

	return (
		<>
			{isLoading || !section4Data ? (
				<LoaderSpinner />
			) : (
				<>
					<Heading type="h1" className="uppercase mb-[64px] mt-[20px]">
						Incident Analysis
					</Heading>
					<div className="flex flex-row gap-[4px]">
						<Heading type="h2" className="uppercase mt-[32px] mb-[32px]">
							Severity
						</Heading>
					</div>
					<div className="gap-2 w-full">
						<RadioV2
							options={section4RadioButtons}
							onChange={(value: string) => {
								handleSection4Update('severityRating', value);
							}}
							defaultId={section4.severityRating}
							className="mt-2 mb-2 text-sm"
							classNameWrapper="gap-4"
							radioDirection={'grid'}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<Heading type="h2" className="uppercase mb-[32px]">
						Incident Category *
					</Heading>
					<FormCheckBoxGroupV2
						checkBoxName="incidentCategory"
						checkBoxGroupData={section4.incidentCategory}
						onHandleChange={handleSection4Update}
						handleOnBlur={handleOnBlur}
						descriptionDirection="flex-col"
						gridColumns={'4'}
						isDisabled={disableIncidentWriteAccess}
						singleSelect={true}
					/>
					{!incidentSection3Injuries && (
						<>
							<Divider
								className="my-[64px]"
								color="!border-t-[4px] border-orange-500"
							/>
							<Heading type="h2">Incident Mechanism *</Heading>
							<FormCheckBoxGroupV2
								checkBoxName="incidentMechanism"
								checkBoxGroupData={section4.incidentMechanism}
								onHandleChange={handleSection4Update}
								gridColumns={'4'}
								otherTextBox={true}
								otherTextBoxValue={section4.incidentMechanismOtherInput}
								otherTextBoxOnChange={(value: string) =>
									handleSection4Update('incidentMechanismOtherInput', value)
								}
								handleOnBlur={handleOnBlur}
								otherTextBoxClassName="w-[400px] xl:-ml-44"
								isDisabled={disableIncidentWriteAccess}
							/>

							<Divider
								className="my-[64px]"
								color="!border-t-[4px] border-orange-500"
							/>
							<Heading type="h2">Incident Agency *</Heading>
							<FormCheckBoxGroupV2
								checkBoxName="incidentAgency"
								checkBoxGroupData={section4.incidentAgency}
								onHandleChange={handleSection4Update}
								gridColumns={'4'}
								otherTextBox={true}
								otherTextBoxValue={section4.incidentAgencyOtherInput}
								otherTextBoxOnChange={(value: string) =>
									handleSection4Update('incidentAgencyOtherInput', value)
								}
								otherTextBoxClassName="w-[400px] lg:-ml-44 rounded-lg"
								isDisabled={disableIncidentWriteAccess}
							/>
							<Divider
								className="my-[64px]"
								color="!border-t-[4px] border-orange-500"
							/>
							<Heading type="h2">MVI Causation</Heading>
							<FormCheckBoxGroupV2
								checkBoxName="mviCausation"
								checkBoxGroupData={section4.mviCausation}
								onHandleChange={handleSection4Update}
								gridColumns={'4'}
								otherTextBox={false}
								handleOnBlur={handleOnBlur}
								otherTextBoxClassName="w-[400px] lg:-ml-44 rounded-lg"
								isDisabled={disableIncidentWriteAccess}
							/>
						</>
					)}
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<ToggleButtonV2
						toggle={isNotifiableIncident}
						setToggle={setIsNotifiableIncident}
						label="Notifiable Incident?"
						required={true}
						isDisabled={disableIncidentWriteAccess}
					/>
					<Text className="text-sm mb-4">
						Notifiable Incidents are those serious incidents which must be
						reported to the State Safety Regulator and include death, serious
						injuries/illnesses (inc injuries requiring hospital admission, head
						injuries, serious lacerations, spinal injuries, serious burns etc)
						and dangerous incidents (including serious near misses) such as
						major vehicle incidents, major enviromental incidents etc.
					</Text>
					{isNotifiableIncident && (
						<>
							<div className="flex flex-row">
								<Input
									type="text"
									value={section4.reportedTo}
									label="Reported to"
									required={true}
									className="w-[350px]"
									onChange={(value: string) =>
										handleSection4Update('reportedTo', value, true)
									}
									onBlur={handleOnBlur}
									isDisabled={disableIncidentWriteAccess}
								/>
								<Input
									type="text"
									value={section4.referenceNumber}
									label="Reference Number"
									required={true}
									className="w-[350px] ml-3"
									onChange={(value: string) =>
										handleSection4Update('referenceNumber', value, true)
									}
									onBlur={handleOnBlur}
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
							<div className="flex flex-row w-full mt-4">
								<TextareaInput
									className="w-full"
									value={section4.notes}
									rows={10}
									label="Notes"
									resize={true}
									onChange={(value: string) =>
										handleSection4Update('notes', value, true)
									}
									onBlur={handleOnBlur}
									isDisabled={disableIncidentWriteAccess}
								/>
							</div>
						</>
					)}
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<Heading type="h2" className="uppercase mb-[32px]">
						Life Saving Rules
					</Heading>
					<ToggleButtonV2
						toggle={isLifeSavingBreach}
						setToggle={setIsLifeSavingBreach}
						label="Was this a breach of Life Saving Rules?"
						required={true}
						isDisabled={disableIncidentWriteAccess}
					/>
					<SlideDown
						open={isLifeSavingBreach}
						className={`${isLifeSavingBreach ? 'my-2' : 'hidden'}`}
					>
						<FormCheckBoxGroupV2
							checkBoxName="lifeSavingRules"
							checkBoxGroupData={section4.lifeSavingRules}
							onHandleChange={handleSection4Update}
							handleOnBlur={handleOnBlur}
							gridColumns={'4'}
							isDisabled={disableIncidentWriteAccess}
							descriptionDirection="flex-col"
							singleSelect={true}
						/>
					</SlideDown>
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<Heading type="h2" className="uppercase mb-[32px]">
						Code of Conduct
					</Heading>
					<ToggleButtonV2
						toggle={isCodeOfConductBreach}
						setToggle={setIsCodeOfConductBreach}
						label="Was this a breach of the Code of Conduct?"
						isDisabled={disableIncidentWriteAccess}
					/>
					{isCodeOfConductBreach && (
						<>
							<Text type="bold">Select breaches</Text>
							<FormCheckBoxGroupV2
								checkBoxName="codeOfConduct"
								checkBoxGroupData={section4.codeOfConduct}
								onHandleChange={handleSection4Update}
								handleOnBlur={handleOnBlur}
								gridColumns={'4'}
								isDisabled={disableIncidentWriteAccess}
							/>
						</>
					)}
					<Divider
						className="my-[64px]"
						color="!border-t-[4px] border-orange-500"
					/>
					<div className="flex justify-between mb-[64px]">
						<Button
							onClick={() => {
								let temp = readTabs;
								temp[3].isRead = true;
								handleValidate();
								setReadTabs(temp);
								setSection('section3');
							}}
							type="primary"
							isDisabled={savingIncident}
							className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
						>
							Back to Incident Injury Details
						</Button>
						<Button
							onClick={() => {
								let temp = readTabs;
								temp[3].isRead = true;
								handleValidate();
								setReadTabs(temp);
								setSection('section5');
							}}
							type="primary"
							isDisabled={savingIncident}
							className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
						>
							Continue to Investigation
						</Button>
					</div>
				</>
			)}
		</>
	);
};

export default IncidentSection4;
